$font-black: "Arial Black", sans-serif;
$font-regular: Arial, sans-serif;

$color-highlight: #eaeaea;
$color-base: #fff;
$color-text: #333;

$font-size: 1.5em;
$font-size-4K: 3em;

.touch-region {
  font-family: $font-regular;
  font-size: $font-size;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: white;

  @media screen and (min-width: 1921px) and (orientation: landscape) {
    font-size: $font-size-4K;
  }

  @media screen and (min-width: 1081px) and (orientation: portrait) {
    font-size: $font-size-4K;
  }
}

.touch-region-container {
  position: fixed;
  left: 0;
  top: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.touch-region-content {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

.touch-region-footer {
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.touch-button {
  background: $color-base;
  border: 1px solid $color-highlight;
  color: $color-text;
  display: flex;
  place-content: center;
  place-items: center;
  column-gap: 0.5em;
  flex: 1 0 auto;

  &:last-child {
    border-right: 0;
  }

  &:first-child {
    border-left: 0;
  }

  @media screen and (min-width: 1921px) and (orientation: landscape) {
    border: 2px solid $color-highlight;
  }

  @media screen and (min-width: 1081px) and (orientation: portrait) {
    border: 2px solid $color-highlight;
  }
}

.touch-button-close {
  background: hsla(0deg, 100%, 100%, 0.8);
  box-shadow: 0 0 10px hsl(0deg, 0%, 0%, 0.25);
  padding: 14px 30px 10px 30px;
  color: $color-text;
  display: flex;
  column-gap: 0.5em;
  place-content: center;
  place-items: center;
  flex: 1 0 auto;
}

.touch-button-icon svg {
  height: $font-size;
}

.touch-button-text {
  font-size: $font-size;
}

.touch-buttons-container {
  bottom: 0;
  position: relative;
  height: 100%;
  display: flex;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    user-select: none;
  }
}
