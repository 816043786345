body {
  height: 100%;
  width: 100%;
  margin: 0;
  bottom: 0;
  position: absolute;
  background-color: black;
}

.root {
  height: 100%;
  width: 100%;
  margin: 0;
  bottom: 0;
}

.app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  .bind-key-container {
    position: absolute;
    bottom: 10px;
    right: 10px;

    .bind-key {
      font-family: Arial, Helvetica, sans-serif;
      letter-spacing: 0.2em;
      color: white;
      margin: 0;
      font-size: 40px;
    }

    &::before {
      position: relative;
      margin-right: 16px;
      content: url("assets/logo.svg");
    }
  }
}

.ql-editor {
  min-height: 200px;
}

.fallback {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("assets/fallback.png");
  background-size: cover;
  background-position: center;
}
